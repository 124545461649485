.axis-locker {
  color: #a2aaad;
  transition: all var(--duration);
  cursor: pointer;

  &:hover {
    color: #041c2c;
  }
  &.isLocked {
    color: #00c7b1;
    &:hover {
      color: #041c2c;
    }
  }

  &.xAxis.min {
    bottom: 6px;
    left: 64px;
  }
  &.xAxis.max {
    bottom: 6px;
    right: 12px;
  }

  &.yAxis.min {
    bottom: 44px;
    left: 28px;
  }
  &.yAxis.max {
    top: 52px;
    left: 6px;
  }
}
